table{
    margin-bottom: 0 !important;
    thead{
        tr{
            th{
                border-bottom: 0 !important;
                border-top: 0 !important;
                vertical-align: middle !important;
                font-size: 13px;
                color: #969696;
                font-weight: 400;
                letter-spacing: 1.5px;
                white-space: nowrap;
            }
        }
    }
    tbody{
        tr{
            font-size: 12px;
            td{
                border-top: 1px solid rgba(0,0,0,0.05);
                padding: 12px;
                vertical-align: middle !important;
                white-space: nowrap;
            }
        }
    }
}

