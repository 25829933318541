.modal-box{
    &.login-lightbox{
        .light-box{
            overflow-y: hidden;
            display: flex;
            flex-direction: column;
            .light-box-header{
                position: relative;
                .title{
                    padding: 15px;
                    color: #323232;
                    text-align: center;
                    font-size: 1.2rem;
                    margin: 0;
                }
                &::before{
                    content: "";
                    position: absolute;
                    width: 90%;
                    height: 2px;
                    background: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.3), rgba(0,0,0,0));
                    left: 50%;
                    bottom: 0;
                    transform: translateX(-50%);
                }
            }
            .light-box-body{
                overflow-y: scroll;
                padding: 30px;
                position: relative;
                .read-more{
                    border: 0;
                    background-color: #dc3545;
                    padding: 10px 36px 10px 24px;
                    color: #fff;
                    border-radius: 5px;
                    font-weight: bold;
                    position: relative;
                    box-shadow: 1px 2px 3px rgba(0,0,0,0.4);
                    &::before{
                        content: "\f054";
                        position: absolute;
                        top: 50%;
                        right: 12px;
                        transform: translateY(-50%);
                        font-family: "Font Awesome 5 Pro";
                        font-weight: bold;
                    }
                }
            }
            .light-box-footer{
                padding: 15px;
                position: relative;
                .login-lightbox-btn{
                    background-color: #555;
                    color: #fff;
                    border: 0;
                    font-weight: bold;
                    padding: 5px 10px;
                    border-radius: 5px;
                    margin-left: 20px;
                    transition: all 0.15s ease-in-out;
                    &:hover{
                        background-color: #dc3545;
                    }
                }
                &::before{
                    content: "";
                    position: absolute;
                    width: 90%;
                    height: 2px;
                    background: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.3), rgba(0,0,0,0));
                    left: 50%;
                    top: 0;
                    transform: translateX(-50%);
                }
            }
        }
    }
}