.MuiPagination-root{
    display: inline-block;
    .MuiPagination-ul{
        li{
            button{
                font-weight: bold;
                margin: 3px;
                @media screen and (max-width: 374px){
                    min-width: 26px;
                    height: 26px;
                    margin: 3px 2px;
                }
                &:hover{
                    background-color: #ddd;
                }
                &:focus{
                    background-color: transparent;
                    outline: none;
                }
            }
            button.Mui-selected{
                background-color:#d1d1d1;
                &:hover{
                    background-color:#d1d1d1;
                }
                &:focus{
                    outline: none;
                }
            }
            .MuiPaginationItem-ellipsis{
            }
        }
    }
}