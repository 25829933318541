.login-page {
	height: 100%;
    min-height: 400px;
	// padding-top: 100px;
	position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    // background: linear-gradient(135deg, #2b9a8b, #0d4643);
	background: linear-gradient(135deg, #0d667a, #095566);
	.logo{
        margin: 0 auto;
		width: 250px;
        background-image: url("../../images/logo/logo.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        &::before{
            content: "";
            display: block;
            padding-top: 20%;
        }
	}
	.title{
		margin-top: 20px;
		color: #fff;
		text-align: center;
		font-size: 18px;
		font-family: 微軟正黑體;
	}
    .logo-wrapper{
		// position: absolute;
        // width: 100%;
		// left: 50%;
		// top: 250px;
		// transform: translateX(-50%);
        width: 100%;
        padding: 20px 0 50px 0;
    }
	.content-box {
        margin: 0 auto;
		width: calc(100% - 30px);
		// min-width: 300px;
		max-width: 400px;
		padding: 20px;
		background: rgba(255,255,255,1);
		border-radius: 5px;
		box-shadow: $main-shadow;
		.input-group-prepend {
			.input-group-text {
				color: black;
				padding-left: 0;
				padding-right: 0;
				width: 20px;
				max-width: 20px;
				border: 0;
				background-color: transparent;
			}
		}
		.phone-select {
			width: 75px;
		}
		.form-control {
			font-size: 14px;
			border: 0;
			border-bottom: 1px solid #efefef;
			border-radius: 0;
			background-color: transparent;
			&:focus {
				border-color: $main-color;
				box-shadow: none;
			}
		}
		.verify-btn {
			margin-left: 15px;
			min-width: 90px;
			max-width: 90px;
			background-color: #00BDB1;
			border: 0;
			color: #fff;
			border-radius: 10px;
			font-size: 12px;
			&:disabled {
				opacity: 0.7;
			}
		}
		.view-pass-btn {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 5px;
			width: 20px;
			text-align: center;
			z-index: 999;
			color: #333;
			font-size: 16px;	
		}
		.other-description {
			font-size: 13px;
		}
		.login-btn{
			width: 100%;
			border: 0;
			border-radius: 10px;
			font-weight: bold;
			background-color: $main-color;
			padding: 11px 0;
			color: $text-dark;
			cursor: pointer;
			&:hover{
				background-color: $secondary-color;
				color: $text-dark;
			}
			&:focus{
				outline: none;
			}
        }
    }
}