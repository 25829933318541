ul.chat-list{
    li.chat-item{
        padding: 10px 15px;
        display: flex;
        &.myself{
            flex-direction: row-reverse;
            .chat-info{
                margin-left: unset;
                margin-right: 20px;
                text-align: right;
                .content{
                    &:before{
                        left: unset;
                        right: -10px;
                        border-radius: 0 0 32px 0;
                    }
                }
            }
        }
        .chat-info{
            margin-left: 20px;
            .name{
                font-weight: bold;
                margin-bottom: 5px;
            }
            .content{
                padding: 10px;
                background-color: #666;
                color: #fff;
                font-weight: bold;
                border-radius: 10px;
                position: relative;
                margin-bottom: 5px;
                display: inline-block;
                word-break: break-word ;
                overflow-wrap: break-word;
                &:before{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -10px;
                    width: 16px;
                    height: 16px;
                    border: 0 solid #666;
                    border-bottom-width: 10px;
                    border-radius: 0 0 0 32px;
                }
            }
            .time{
                font-size: 0.75rem;
                letter-spacing: 0.5px;
                color: #969696;
            }
        }
    }
}

.chat-bar{
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 768px;
    background-color: rgba(255,255,255,0.5);
    backdrop-filter: blur(5px);
    box-shadow: 0 -2px 3px rgba(0,0,0,0.2);
    z-index: 2;
    .chat-form{
        width: 100%;
        min-height: 75px;
        padding: 10px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        textarea.chat-input{
            // border: 2px solid #464649;
            width: calc(100% - 50px);
            background-color: #464649;
            padding: 10px 30px 10px 10px;
            font-weight: bold;
            border-radius: 5px;
            color: #fff;
            resize: none;
            min-height: 45.6px;
            transition: border, background-color 0.1s ease-in;
            overflow: hidden;
            &::placeholder{
                color: #aaa;
            }
            &:focus{
                background-color: #000;
                // border: 2px solid #000;
            }
        }
        .chat-send{
            width: 50px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            font-size: 24px;
            cursor: pointer;
            background-color: transparent;
            border: 0;
            &:disabled{
                color: #969696;
                cursor: default;
            }
        }
    }
}

.chat-paused{
    padding: 10px 0;
    background-color: #323232;
    color: #fff;
    font-weight: bold;
    max-width: 738px;
    width: calc(100% - 30px);
    text-align: center;
    border-radius: 5px;
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
}