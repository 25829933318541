.common-form{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 60px;
    .form-container{
    }
}
.submit-btn{
    display: block;
    text-align: center;
    width: 100%;
    font-size: 1rem;
    font-weight: 700;
    font-family: 微軟正黑體;
    background-color: #2088C2;
    color: #fff;
    appearance: none;
    cursor: pointer;
    padding: 8px 0px;
    border: 0;
    border-radius: 6px;
    transition: all 0.1s ease-in-out 0s;
    line-height: 1.8;
    &.logout{
        background-color: rgba(0,0,0,0.1);
        color: #555;
    }
}

.form-group{
    margin: 0;
    margin-bottom: 15px;
    .label-group{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        label{
            font-weight: bold;
        }
    }
}

.input-group{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    background-color: rgba(0,0,0,0.05);
    border-radius: 3px;
    
    &.tiny{
        max-width: 100px;
    }
    .phone-code-select{
        max-width: 80px;
        margin-right: 10px;
    }
    > input{
        border: 0;
        flex: 1;
        width: 1px;
        padding: 12px 0 12px 10px;
        color: #555;
        background-color: transparent;
        &::placeholder{
            color: #aaa;
        }
        &:read-only{
            background-color: #fff;
            border-radius: 0;
            padding: 5px 0;
            border-bottom: 1px solid rgba(0,0,0,0.1);
        }
    }
    select{
        width: 100%;
        border: 0;
        color: #555;
        // border-bottom: 1px solid #d9d9d9;
        cursor: pointer;
        padding: 12px 30px 12px 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='black' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
        &.phone-code-select{
            border-radius: 0;
            border-bottom: 1px solid #d9d9d9;
            max-width: 80px;
            background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='black' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
        }
        &.address_city_select{
            flex: 1;
        }
        &.address_district_select{
            flex: 1;
        }
        option{
            color: $text-dark;
        }
    }
}

.form-desc{
    padding: 15px;
    border-radius: 10px;
    background-color: rgba(0,0,0,0.05);
    .title{
        font-family: 微軟正黑體;
        font-weight: bold;
        font-size: 1.1rem;
        margin-bottom: 15px;
    }
}

.view-pass-btn{
    border: 0;
    background-color: transparent;
    padding: 5px 12px;
}

.email_auth_btn{
    height: 100%;
    border: 0;
    // background-color: #ffea00;
    background-color: transparent;
    // border: 1px solid #969696;
    color: #dab42e;
    // border-radius: 30px;
    padding: 5px 12px;
    white-space: nowrap;
    font-weight: bold;
    font-family: 微軟正黑體;
}

.MuiFormControl-root{
    label.MuiInputLabel-formControl{
        font-size: 14.4px !important;
        color: #969696;
        transform: translate(0, 18px) scale(1);
        &.MuiInputLabel-shrink{
            transform: translate(0, 1.5px) scale(0.9);
            color: #555;
        }
    }
    .MuiInput-formControl{
        &::before{
            border-bottom: 1px solid #d9d9d9;
        }
        &:hover{
            &::before{
                border-bottom: 1px solid #969696 !important;
            }
        }
        &::after{
            border-bottom: 2px solid #2088C2;
        }
    }
    input.MuiInputBase-input{
        padding: 8px 0;
        color: #555;
    }
}