.user-avatar-img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 2px solid #fff;
    background-color: #fff;
    &.non-avatar{
        background-image: url('../../images/icon/userAvatar.svg') !important;
        background-size: 50%;
        background-color: #DEDFE1;
        &.register{
            background-image: url('../../images/icon/userCamera.svg') !important;
            background-size: 50%;
            background-color: #DEDFE1;
        }
    }
    
    input{
        position: absolute;
        left: 0;
        top: 0;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        opacity: 0;
        cursor: pointer;
        z-index: 1;
    }
}