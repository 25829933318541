.switch {
  position: relative;
  display: inline-block;
  width: 52px !important;
  max-width: 52px !important;
  min-width: 52px !important;
  height: 20px;
  margin: 0;
  input{
      opacity: 0;
      width: 0;
      height: 0;
      &:checked ~ .slider:before{
          background-color: #fff;
      }
  }
  .slider{
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $text-secondary;
      -webkit-transition: .4s;
      transition: .4s;
      &::before{
          position: absolute;
          content: "";
          height: 26px;
          width: 26px;
          top: 50%;
          left: -1px;
          transform: translateY(-50%);
          background-color: $text-third;
          -webkit-transition: .4s;
          transition: .4s;
      }
  }
}

input:checked + .slider {
  background-color: #4BD964;
}

input:checked + .slider:before {
  -webkit-transform: translateY(-50%) translateX(28px);
  -ms-transform: translateY(-50%) translateX(28px);
  transform: translateY(-50%) translateX(28px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
  border: 1px solid #d1d1d1;
  box-shadow: 0 2px 3px rgba(0,0,0,0.2);
}