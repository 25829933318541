// Fonts
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+TC:100,300,400,500,700,900|Montserrat:400,700,200|Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@100;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kodchasan:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

// FontAwesome
@import '../../fonts/fontawesome/scss/fontawesome';

$Noto-Sans: 'Noto Sans TC', sans-serif;
$Default-font: 'Microsoft JhengHei', sans-serif;
$fontRoboto: 'Roboto', sans-serif;
$fontMPlus: 'M PLUS 1p', sans-serif;
$fontKodchasan: 'Kodchasan', sans-serif;
$fontNumber: 'Kodchasan', sans-serif;


