.icon {
    display: inline-block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    text-align: center;
    line-height: 22px;
    font-style: initial;
}

i{
    &.common-icon{
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 30px;
        display: block;
        &.icon-sm{
            width: 20px;
        }
        &.icon-lg{
            width: 38px;
        }
        &::after{
            content: "";
            display: block;
            padding-top: 100%;
        }
        &.bidRecord{
            background-image: url('../../images/icon/bidRecord.svg');
        }
    }
}