.tab-container{
    border-radius: 5px;
    background-color: #343a40;
    .tab-list{
        display: flex;
        position: relative;
        span.active-line{
            height: 3px;
            border-radius: 3px;
            position: absolute;
            bottom: 0;
            background-color: $main-color;
            transition: left 0.3s ease-in-out;
        }
        .tab-item{
            flex: 1;
            text-align: center;
            color: #aaa;
            padding: 15px 0;
            font-weight: bold;
            cursor: pointer;
            &.active{
                position: relative;
                color: $main-color;
                &:hover{
                    background-color: rgba(255,255,255,0.1);
                    color: $main-color;
                }
            }
            &:hover{
                background-color: rgba(255,255,255,0.1);
                color: #fff;
            }
        }
    }
    .tab-body{
        padding: 15px;
        color: #fff;
    }
}