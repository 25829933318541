.notification-page{
    padding-top: 60px;
    ul.notification-list{
        padding: 15px 0;
        li.notification-item{
            border-bottom: 1px solid rgba(0,0,0,0.03);
            cursor: pointer;
            &.unread{
                background-color: #fffff2;
                .title{
                    padding-left: 15px;
                    position: relative;
                    &::before{
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        width: 5px;
                        height: 5px;
                        background-color: #ffc107;
                        border-radius: 50%;
                    }
                }
            }
            .notification-link{
                padding: 10px 15px;
                display: flex;
                .content{
                    flex: 1;
                    margin-right: 15px;
                    width: 1px;
                    .title{
                        font-size: 1rem;
                        font-weight: bold;
                        font-family: 微軟正黑體;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        margin-bottom: 10px;
                    }
                    .info{
                        font-size: 0.8rem;
                        color: #969696;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
                .time{
                    font-size: 0.75rem;
                }
            }
        }
    }
}