.router-wrapper {
    position: relative;
    width: 100%;
    max-width: 768px;
    height: 100%;
    > div {
        position: absolute;
        height: 100%; 
        width: 100%;
    }
}

.forward-enter {
    opacity: 0;
    transform: translateX(100%);
}

.forward-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 500ms;
}

.forward-exit {
    opacity: 1;
    transform: translateX(0);
}

.forward-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: all 500ms;
}

.back-enter {
    opacity: 0;
    transform: translateX(-100%);
}

.back-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 500ms;
}

.back-exit {
    opacity: 1;
    transform: translateX(0);
}

.back-exit-active {
    opacity: 0;
    transform: translate(100%);
    transition: all 500ms;
}