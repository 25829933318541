.dialog-box {
    // &.active {
    //     &::before{
    //         content: '';
    //         position: fixed;
    //         top: 0;
    //         left: 0;
    //         bottom: 0;
    //         right: 0;
    //         background-color: transparent;
    //         transition: all 0.3s ease-in-out;
    //         z-index: 100;
    //         visibility: visible !important;
    //         opacity: 1 !important;
    //         background-color: rgba(0,0,0,0.55) !important;
    //     }
    // }
    .dialog-content{
        visibility: hidden;
        opacity: 0;
        position: absolute;
        min-width: 250px;
        height: 400px;
        top: 30px;
        right: 9px;
        border-radius: 10px;
        background-color: #fff;
        transition: all 0.3s ease-in-out;
        z-index: 101;
        box-shadow: 0 0 5px rgba(0,0,0,0.2);
        &.active{
            opacity: 1 !important;
            visibility: visible !important;
        }
        &:before{
            content: '';
            position: absolute;
            right: 17px;
            top: 1px;
            transform: rotate(-45deg);
            width: 0;
            height: 0;
            border: 6px solid #fff;
            transform-origin: 0 0;
            box-shadow: 2px -2px 3px 0 rgba(0,0,0,0.15);
            z-index: -1;
        }
        .dialog-list{
            overflow-y: scroll;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            .dialog-item{
                padding: 10px 15px;
                border-bottom: 1px solid #d1d1d1;
                text-align: left;
                margin-left: 8px;
            }
        }
    }
}