.modal-box{
    .confirm-box{
        width: 90%;
        max-width: 415px;
        background-color: #fff;
        transition: all 0.2s ease-in-out;
        border-radius: 10px;
        .confirm-box-header{

        }
        .confirm-box-body{
            font-size: 1rem;
            letter-spacing: 1px;
            padding: 15px;
            color: $text-dark;
        }
        .confirm-box-footer{
            text-align: right;
            padding: 15px;
            .confirm-btn{
                -webkit-appearance: none;
                appearance: none;
                padding: 6px 16px;
                border-radius: 3px;
                border: 0;
                margin-left: 20px;
                &.cancel{
                    background-color: transparent;
                }
                &.confirm{
                    background-color: rgba(0,0,0,0.1);
                    color: #555;
                }
            }
        }
    }
}

.confirm-transition-enter{
    visibility: hidden !important;
    background-color: rgba(0,0,0,0) !important;
    .confirm-box{
        transform: scale(0.9) !important;
        opacity: 0 !important;
    }
}
.confirm-transition-enter-active{
    visibility: visible !important;
    background-color: rgba(0,0,0,0.55) !important;
    transition: all 300ms ease-in-out !important;
    .confirm-box{
        transform: scale(1) !important;
        opacity: 1 !important;
        transition: all 200ms ease-in-out !important;
    }
}
.confirm-transition-enter-done{
    visibility: visible !important;
    background-color: rgba(0,0,0,0.55) !important;
    .confirm-box{
        transform: scale(1) !important;
        opacity: 1 !important;
    }
}

.confirm-transition-exit{
    visibility: visible !important;
    background-color: rgba(0,0,0,0.55) !important;
    .confirm-box{
        transform: scale(1) !important;
        opacity: 1 !important;
    }
}
.confirm-transition-exit-active{
    visibility: hidden !important;
    background-color: rgba(0,0,0,0) !important;
    transition: all 300ms ease-in-out !important;
    .confirm-box{
        transform: scale(0.9) !important;
        opacity: 0 !important;
        transition: all 200ms ease-in-out !important;
    }
}
.confirm-transition-exit-done{
    visibility: hidden !important;
    background-color: rgba(0,0,0,0) !important;
    .confirm-box{
        transform: scale(0.9) !important;
        opacity: 0 !important;
    }
}