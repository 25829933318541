.modal-box{
    .light-box{
        width: 90%;
        max-width: 415px;
        transform: scale(0.9);
        opacity: 0;
        // visibility: hidden;
        border-radius: 10px;
        overflow: hidden;
        background-color: #fff;
        transition: all 0.2s ease-in-out;
        max-height: 80%;
        position: relative;
        display: flex;
        flex-direction: column;
        .light-box-body{
            overflow-y: scroll;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        // &.active{
        //     opacity: 1;
        //     visibility: visible;
        //     transform: scale(1);
        // }
        .light-box-close{
            width: 30px;
            height: 30px;
            background-color: rgba(0,0,0,0.2);
            position: absolute;
            top: 10px;
            right: 10px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:hover{
                background-color: rgba(0,0,0,0.3);
            }
            &::before, &::after{
                content: "";
                position: absolute;
                width: 20px;
                height: 1.5px;
                background-color: #fff;
            }
            &::before{
                transform: rotate(45deg);
            }
            &::after{
                transform: rotate(135deg);
            }
        }
    }
}

.lightbox-transition-enter{
    opacity: 0 !important;
    visibility: hidden !important;
    background-color: rgba(0,0,0,0) !important;
    .light-box{
        transform: scale(0.9) !important;
        opacity: 0 !important;
    }
}
.lightbox-transition-enter-active{
    opacity: 1 !important;
    visibility: visible !important;
    background-color: rgba(0,0,0,0.55) !important;
    transition: all 300ms ease-in-out !important;
    .light-box{
        transform: scale(1) !important;
        opacity: 1 !important;
        transition: all 200ms ease-in-out !important;
    }
}
.lightbox-transition-enter-done{
    opacity: 1 !important;
    visibility: visible !important;
    background-color: rgba(0,0,0,0.55) !important;
    .light-box{
        transform: scale(1) !important;
        opacity: 1 !important;
    }
}

.lightbox-transition-exit{
    opacity: 1 !important;
    .light-box{
        transform: scale(1) !important;
        opacity: 1 !important;
    }
}
.lightbox-transition-exit-active{
    opacity: 0 !important;
    transition: all 300ms ease-in-out !important;
    .light-box{
        transform: scale(0.9) !important;
        opacity: 0 !important;
        transition: all 200ms ease-in-out !important;
    }
}
.lightbox-transition-exit-done{
    opacity: 0 !important;
    .light-box{
        transform: scale(0.9) !important;
        opacity: 0 !important;
    }
}