.common-header{
    position: fixed;
    top: -1px;
    left: 0;
    width: 100%;
    color: #fff;
    padding-top: 1px;
    height: 61px;
    z-index: 1;
    backdrop-filter: blur(10px);
    > nav{
        width: 100%;
        max-width: 768px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        .nav-item{
            text-align: center;
        }
        .nav-left{
            text-align: left;
            
            .back-btn{
                cursor: pointer;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                padding-left: 15px;
                i{
                    font-weight: 400;
                }
            }
        }
        .nav-right{
            cursor: pointer;
        }
        .nav-left, .nav-right{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 100%;
            font-size: 1rem;
            transition: all 0.3s ease;
        }
        .nav-title{
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: bold;
            font-family: 微軟正黑體;
            font-size: 1rem;
        }
    }
}