.home-page{
    padding-top: 60px;
    padding-bottom: 90px;
    .home-banner{
        width: 100%;
        max-width: 768px;
        overflow: hidden;
        .banner-item{
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            &::before{
                content: "";
                display: block;
                padding-top: 40%;
            }
        }
    }
    .home-news{
        display: flex;
        align-items: center;
        flex: 1;
        height: 50px;
        padding: 5px 15px;
        z-index: 2;
        > .swiper-container{
            height: 100%;
            width: 100%;
            overflow: hidden;
            .swiper-wrapper{
                .swiper-slide{
                    line-height: 40px;
                    font-family: 微軟正黑體;
                    font-weight: bold;
                }
            }
        }
    }
    table.market-table{
        thead{
            tr{
                th{
                    &:last-child{
                        text-align: right;
                    }
                }
            }
        }
        tbody{
            tr{
                td{
                    .opened{
                        width: 75px;
                        height: 30px;
                        border-radius: 3px;
                        box-shadow: 1px 2px 3px rgba(0,0,0,0.2);
                        background-color: #b0b0b0;
                        color: #fff;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: bold;
                        font-family: 微軟正黑體;
                        &.lower{
                            background-color: #e63946;
                            color: #fff;
                        }
                        &.higher{
                            background-color: #11999E;
                            color: #fff;
                            &:before{
                                content: "+";
                            }
                        }
                    }
                }
            }
        }
    }
    .home-wrapper{
        position: relative;
        padding: 15px 0;
        background-color: #fff;
        border-radius: 30px 30px 0 0;
        &::before{
            content: "";
            position: absolute;
            top: 6px;
            left: 50%;
            transform: translateX(-50%);
            width: 50px;
            height: 4px;
            border-radius: 15px;
            background-color: #cecece;
        }
        .home-news{
            display: flex;
            align-items: center;
            flex: 1;
            height: 40px;
            padding: 0 15px;
            z-index: 2;
            > .swiper-container{
                height: 100%;
                width: 100%;
                overflow: hidden;
                .swiper-wrapper{
                    .swiper-slide{
                        line-height: 40px;
                        font-family: 微軟正黑體;
                        font-weight: bold;
                    }
                }
            }
        }
        .real-time-bar{
            .real-time-item{
                font-size: 12px;
                padding-left: 15px;
                .reak-time-header{
                    display: flex;
                    align-items: center;
                    @media screen and (max-width: 415px){
                        flex-direction: column;
                        align-items: flex-start;
                        font-size: 0.9rem;
                    }
                }
            }
        }
        .home-banner{
            width: 100%;
            max-width: 768px;
            .banner-item{
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                &::before{
                    content: "";
                    display: block;
                    padding-top: 40%;
                }
            }
        }
        .news-container{
            padding: 15px 0;
            ul.news-list{
                li.news-item{
                    a.news-link{
                        display: block;
                        padding: 10px 15px;
                        .title{
                            font-weight: bold;
                            font-family: 微軟正黑體;
                            font-size: 1rem;
                            margin-bottom: 5px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .time{
                            color: #969696;
                            font-size: 0.85rem;
                        }
                    }
                }
            }
        }
    }
}


ul.tools-list{
    display: flex;
    border-radius: 10px;
    font-size: 0.75rem;
    li.tools-item{
        flex: 1;
        a{
            display: block;
            text-align: center;
            padding: 10px 0;
            > i{
                font-size: 24px;
                margin: 0 auto;
            }
            .label{
                margin-top: 5px;
            }
        }
    }
}