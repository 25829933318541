//big-btn---------------------------------------
.main-btn{
    display: block;
    text-align: center;
    padding: 11px 0;
    margin: 15px 0;
    width: 100%;
    border: 0;
    border-radius: 30px;
    font-weight: bold;
    font-size: 1rem;
    color: #323232;
    background-color: #FFEA00;
    -webkit-appearance: none;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    &:hover{
        color: #323232;
    }
    &:focus{
        outline: none;
    }
    &.white{
        background-color: #fff;
        color: $text-dark;
        &:hover{
            color: $text-dark;
        }
    }
    &.line{
        background-color: #00B800;
        color: #fff;
        &:hover{
            background-color: #00aa00;
        }
    }
    &:disabled{
        background-color: #969696;
        color: #666;
        pointer-events: none;
    }
}

//small-btn---------------------------------------
.secondary-btn{
    width: 85px;
    border-radius: 10px;
    padding: 5px 10px;
    position: absolute;
    left: 50%;
    bottom: 5px;
    transform: translateX(-50%);
    cursor: pointer;
    font-weight: bold;
    &:focus{
        outline: none;
    }
    &.yellow{
        border: 1px solid $text-dark;
        background-color: $main-color;
        color: $text-dark;
        transition: all 0.3s;
        &:hover{
            background-color: $secondary-color;
        }
    }
    &.red{
        border: 0;
        background-color: $main-red;
        color: #fff;
        pointer-events: none;
    }
    &.white{
        border: 1px solid $main-color;
        color: $main-color;
        background-color: #fff;
        pointer-events: none;
    }
    &.gray{
        background-color: #ebebeb;
        color: $text-dark;
        border: 0;
        pointer-events: none;
    }
}