.swiper-pagination{
    .swiper-pagination-bullet{
        width: 20px;
        height: 3.5px;
        border-radius: 0;
        transition: all 0.15s ease-in-out;
        &.swiper-pagination-bullet-active{
            background: #555;
        }
    }
}