/*!
 * Global styling for this template
 */
*{
    box-sizing: border-box;
}
.font-family-number{
    font-family: $fontNumber;
    // font-weight: 900;
}

.font-weight-bold-xl{
    font-family: $Noto-Sans;
    font-weight: 700;
}

.font-weight-bold{
    font-family: 微軟正黑體;
    font-weight: bold;
}

@font-face{
    src:url(../fonts/genj/GenJ.ttf);
    font-family: genj;
}

.bg-gray{
    background-color: #969696;
}

.text-white{
    color: #fff !important;
}
.text-dark{
    color: $text-dark !important;
}
.text-secondary{
    color: $text-secondary !important;
}
.text-third{
    color: $text-third !important;
}
.text-main{
    color: $main-color !important;
}
.text-yellow{
    color: #ffea00 !important;
}
.text-red{
    color: $main-red !important;
}
.text-right{
    text-align: right !important;
}
.text-middle{
    text-align: center !important;
}
.text-left{
    text-align: left !important;
}

.text-ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.shop-btn-main {
    background-color: #c19e68;
    color: #fff;
}

ul{
    margin: 0;
    padding: 0;
    li{
        list-style: none;
    }
}

label{
    margin: 0;
    cursor: unset;
}

a{
    color: unset;
    text-decoration: none;
    &:hover{
        color: unset;
        text-decoration: none;
    }
    &:focus{
        outline: none;
    }
}

@for $i from 0 through 8 {
    .pd-#{$i}{
        padding: $i*5px !important;
    }
    .pdx-#{$i}{
        padding-right: $i*5px !important;
        padding-left: $i*5px !important;
    }
    .pdy-#{$i}{
        padding-top: $i*5px !important;
        padding-bottom: $i*5px !important;
    }
    .pdt-#{$i}{
        padding-top: $i*5px !important;
    }
    .pdr-#{$i}{
        padding-right: $i*5px !important;
    }
    .pdb-#{$i}{
        padding-bottom: $i*5px !important;
    }
    .pdl-#{$i}{
        padding-left: $i*5px !important;
    }
    .mg-#{$i}{
        margin: $i*5px !important;
    }
    .mgx-#{$i}{
        margin-right: $i*5px !important;
        margin-left: $i*5px !important;
    }
    .mgy-#{$i}{
        margin-top: $i*5px !important;
        margin-bottom: $i*5px !important;
    }
    .mgt-#{$i}{
        margin-top: $i*5px !important;
    }
    .mgr-#{$i}{
        margin-right: $i*5px !important;
    }
    .mgb-#{$i}{
        margin-bottom: $i*5px !important;
    }
    .mgl-#{$i}{
        margin-left: $i*5px !important;
    }
}

@for $i from 0 through 6 {
    .letter-#{$i}{
        letter-spacing: $i*1px !important;
    }
}

body::-webkit-scrollbar {
    display: none;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-track {
    margin: 2px 0;
}
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 100px;
    border-radius: 100px;
    background: rgb(159,159,162);
}

html, body {
    min-height: 100%;
    height: 100%;
}

html.noscroll {
    position: fixed;
    /* overflow-y: scroll; */
    width: 100%;
}

body.noscroll {
    position: fixed;
    /* overflow-y: scroll; */
    width: 100%;
}

body {
    // font-family: genj;
    // font-family: $Noto-Sans;
    font-family: 微軟正黑體;
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #555555;
    background-color: #F8F8F8;
    &.white {
        background-color: #fff;
        #app {
            background-color: #fff;
        }
    }
    &.start{
        // background-color: #D66D51;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        // background-image: url("../images/background/start_page_bg.jpg");
        #app{
            // background-color: #D66D51;
        }
    }
    &.login{
        #app {
            max-width: 100%;
        }
    }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $Default-font;
    margin: 0;
}

h6 {
    &.page-item-title {
        position: relative;
        margin-bottom: 8px;
        color: #000;
        font-size: 0.9rem;
        .more {
            position: absolute;
            right: 0;
            font-size: 13px;
            color: #9a9a9a;
            &::after {
                content: "\f105";
                font-family: 'Font Awesome 5 Pro';
                font-weight: 300;
                margin-left: 10px;
            }
        }
    }
}

.text-tiny{
    font-size: 0.7em;
}
.text-small{
    font-size: 0.85em;
}
.text-big{
    font-size: 1.4em;
}
.text-huge{
    font-size: 1.8em;
}

.font-size-xl{
    font-size: 1.1rem !important;
}
.font-size-lg{
    font-size: 1rem;
}
.font-size-md{
    font-size: 0.95rem;
}
.font-size-sm{
    font-size: 0.85rem;
}
.font-size-xs{
    font-size: 0.8rem;
}
.font-size-ss{
    font-size: 0.75rem;
}

input[type=text],
input[type=password],
input[type=tel],
textarea,
button {
	appearance: none;
}

input,
select,
textarea,
button {
    appearance: none;
    outline: none;
}

button {
    &:focus {
        outline: none;
    }
}

input:disabled,
textarea:diabled {
    -webkit-text-fill-color: inherit;
    -webkit-opacity: 1;
    color: inherit;
}

.fixed-top {
    z-index: 9999;
}

.sticky {
    position: fixed;
    top: 54px;
    width: 100%;
}

#root {
    height: 100%;
}

.container-fluid {
    // max-width: 768px;
    padding-left: 0;
    padding-right: 0;
}

.custom-select {
    height: auto;
}

.no-data {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    font-size: 0.85rem;
    font-weight: bold;
    line-height: 24px;
    &::before {
        display: block;
		content: "";
		height: 100px;
		text-shadow: 2px 2px 4px rgba(0,0,0,0.7);
		background-image: url('../images/web/default@3x_white.png');
		background-position: 49% center;
		background-size: 100px 100px;
		background-repeat: no-repeat;
    }
}

.coming-soon {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    color: #fff;
    font-size: 0.85rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
    &::before {
        display: block;
		content: "";
		height: 100px;
		text-shadow: 2px 2px 4px rgba(0,0,0,0.7);
		background-image: url('../images/web/comingsoon.png');
		background-position: center;
		background-size: 138px 100px;
		background-repeat: no-repeat;
    }
}

body.swal2-iosfix {
    position: static !important;
}

#app {
    margin: 0 auto;
    position: relative;
    min-height: 100%;
    height: 100%;
    width: 100%;
    max-width: 768px;
    // background-color: #F8F8F8;
    // background-color: #191C1F;
}

main {
    position: relative;
    height: 100%;
    // padding-top: 60px;
    // padding-bottom: 90px;
    // overflow: hidden;
    // transition: 0.8s;
    &.non-header{
        height: calc(100% - 74px);
        top: 0;
    }
    &.non-footer{
        height: calc(100% - 60px);
        bottom: 0;
    }
    &.non-header + &.non-footer {
        height: 100%;
        top: 0;
        bottom: 0;
    }
}

.absolute-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.page-container {
    padding: 0 15px;
    overflow: hidden;
}

.copy-text{
    border: 0;
    background-color: transparent;
    color: $main-color;
    &:focus{
        color: $main-color;
        outline: none;
    }
    &:hover{
        color: $main-color;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

pre{
    margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-weight: inherit;
	font-style: inherit;
	font-size: 100%;
	font-family: inherit;
	vertical-align: baseline;
}

.modal-box{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    visibility: hidden;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    &.active{
        visibility: visible;
        background-color: rgba(0,0,0,0.55);
    }
}

.language-select{
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    cursor: pointer;
    color: #fff;
    background-color: transparent;
    font-weight: bold;
    option{
        color: $text-dark;
    }
}

h5{
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 15px;
}


// input:-webkit-autofill,
// input:-webkit-autofill:hover, 
// input:-webkit-autofill:focus, 
// input:-webkit-autofill:active  {
//     -webkit-box-shadow: 0 0 0 30px #262937 inset !important;
// }


.common-link{
    display: flex;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 25px;
    box-shadow: 1px 2px 3px rgba(0,0,0,0.2);
    > a, > div, > button{
        border: 0;
        background-color: transparent;
        display: block;
        flex: 1;
        font-weight: bold;
        padding: 10px 0;
        text-align: center;
        position: relative;
        &::before{
            content: '';
            position: absolute;
            width: 1.5px;
            height: 70%;
            background-color: rgba(0,0,0,0.1);
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        &:first-child{
            &::before{
                display: none;
            }
        }
        i,img{
            margin-bottom: 10px;
            width: 24px;
            font-size: 20px;
        }
    }
}

input[type="checkbox"],
input[type="radio"]{
    cursor: pointer;
    min-width: 20px;
    width: 20px;
    min-height: 20px;
    height: 20px;
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid $text-secondary;
    border-radius: 3px;
    position: relative;
    &:checked{
        &:before{
            content: '\f00c';
            font-family: 'Font Awesome 5 Pro';
            font-size: 12px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            color: #fff;
        }
        background-color: $main-color;
        border: 1px solid transparent;
    }
    &:focus{
        outline: none;
    }
}

.page-100vh{
    padding-top: 60px;
    height: 100%;
    overflow-y: auto;
    background-color: #fff;
}

.common-title{
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 微軟正黑體;
    padding: 0 15px;
    display: flex;
    align-items: center;
}