.popover-box{
    &.no-arrow{
        .arrow{
            display: none;
        }
    }
    .popover{
        border: 1px solid #d1d1d1;
        .popover-inner{
            .popover-header{
                
            }
            .popover-body{
                padding: 10px 0;
                display: flex;
                flex-direction: column;
                a,button{
                    border: 0;
                    background-color: transparent;
                    font-weight: bold;
                    color: #666;
                    padding: 7px 20px;
                    display: flex;
                    align-items: center;
                    &:hover{
                        background-color: rgba(0,0,0,0.05);
                        color: #323232;
                    }
                    i{
                        width: 20px;
                        text-align: center;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}