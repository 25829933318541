.modal-box{
    .overlay-box{
        position: absolute;
        bottom: -100%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 415px;
        height: calc(100% - 60px);
        border-radius: 20px 20px 0 0;
        box-shadow: 0 0 5px rgba(0,0,0,0.2);
        transition: all 1s cubic-bezier(.2,1,.2,1);
        overflow: hidden;
        &.active{
            bottom: 0;
        }
        .overlay-box-header{
            width: 100%;
            height: 50px;
            background-color: #fff;
            position: relative;
            text-align: center;
            .overlay-box-title{
                font-weight: bold;
                line-height: 50px;
                font-size: 1rem;
            }
            .overlay-box-close{
                position: absolute;
                width: 30px;
                height: 30px;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
                cursor: pointer;
                background-color: #eee;
                border-radius: 5px;
                &:hover::before, &:hover::after{
                    background-color: #666;
                }
                &::before, &::after{
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 20px;
                    height: 2px;
                    background-color: $text-secondary;
                    border-radius: 2px;
                    transition: all 0.2s ease-in-out;
                }
                &::before{
                    transform: translate(-50%,-50%) rotate(45deg);
                }
                &::after{
                    transform: translate(-50%,-50%) rotate(135deg);
                }
            }
        }
        .overlay-box-body{
            padding: 15px;
            overflow-y: scroll;
            height: calc(100% - 50px);
        }
    }
}