.dashboard-page {
    padding-top: 60px;
    // padding-bottom: 90px;
    &::-webkit-scrollbar{
        display: none;
    }
    .personal-info{
        padding: 20px 15px;
        width: 100%;
        margin: 0 auto;
        color: #555;
        position: relative;
        .name{
            font-weight: 700;
            font-family: "Noto Sans TC", sans-serif;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            font-size: 20px;
        }
    }
    .bid-record-link{
        display: inline-block;
        color: #0d667a;
        font-size: 18px;
        cursor: pointer;
        .bid-icon{
            margin: 0 auto;
            width: 75px;
            height: 55px;
            background-color: #0d667a;
            // background: linear-gradient(135deg, #305AAC, #0E2267);
            border-radius: 10px;
            margin-bottom: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            i{
                width: 40px;
            }
        }
    }
    .dashboard-wrapper{
        position: relative;
        min-height: 100%;
        ul.dashboard-box{
            display: flex;
            border-radius: 10px;
            margin-bottom: 5px;
            font-size: 0.75rem;
            li.dashboard-item{
                flex: 1;
                a{
                    display: block;
                    text-align: center;
                    padding: 15px 0;
                    > i{
                        font-size: 24px;
                        margin: 0 auto;
                    }
                    .label{
                        margin-top: 10px;
                    }
                }
            }
        }
        .levelup-box{
            border-radius: 10px;
            padding: 15px;
            box-shadow: 0 0 5px rgba(0,0,0,0.1);
            position: relative;
            display: block;
            overflow: hidden;
            background: linear-gradient(135deg, #DCF0FF 0%, #DCF0FF00 50%);
            .bubble{
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 150px;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }
        }
    }
    ul.dashboard-list{
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
        li.dashboard-item{
            border-bottom: 1px solid rgba(0,0,0,0.03);
            > a.dashboard-link, > div.dashboard-link{
                padding: 10px 35px 10px 15px;
                background-color: #fff;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                position: relative;
                .icon{
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    color: #555;
                    margin-right: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                }
                &::before{
                    content: "\f105";
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translateY(-50%);
                    font-family: "Font Awesome 5 Pro";
                    font-size: 18px;
                    font-weight: 100;
                    color: #999;
                }
            }
        }
    }
}



.tools-box{
    flex: 1;
    border-radius: 10px;
    background-color: #fff;
    padding: 10px;
    margin-left: 10px;
    margin-bottom: 15px;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    transition: all 0.15s ease-in-out;
    &:first-child{
        margin-left: 0;
    }
    .content{
        margin-top: 10px;
        font-size: 0.8rem;
        text-align: right;
    }
}