.modal-box{
    &.datepicker-light-box{
        .light-box{
            .light-box-header{
            }
            .light-box-body{
                .react-datepicker{
                    font-family: unset;
                    width: 100%;
                    border: 0;
                    display: block;
                    > button {
                        top: 22px;
                        transform: translateY(-50%);
                        border: 0px solid red;
                        width: 30px;
                        height: 30px;
                        text-indent: unset;
                        &.react-datepicker__navigation--next{
                            &::before{
                                content: "\f105";
                                position: absolute;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 100%;
                                height: 100%;
                                background-color: #FFEA00;
                                font-family: "Font Awesome 5 Pro";
                                font-size: 24px;
                                font-weight: 100;
                            }
                        }
                        &.react-datepicker__navigation--previous{
                            &::before{
                                content: "\f104";
                                position: absolute;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 100%;
                                height: 100%;
                                background-color: #FFEA00;
                                font-family: "Font Awesome 5 Pro";
                                font-size: 24px;
                                font-weight: 100;
                            }
                        }
                    }
                    .react-datepicker__month-container{
                        width: 100%;
                        .react-datepicker__header{
                            background-color: transparent;
                            border: 0;
                            padding: 0;
                            .react-datepicker__current-month{
                                display: none;
                            }
                            .react-datepicker__header__dropdown--select{
                                padding: 10px 0;
                                background-color: #FFEA00;
                                select {
                                    background-color: transparent;
                                    border: 0;
                                    font-weight: bold;
                                    font-size: 16px;
                                }
                            }
                            .react-datepicker__day-names{
                                padding-top: 10px;
                                display: flex;
                                .react-datepicker__day-name{
                                    flex: 1;
                                    font-weight: bold;
                                    font-size: 1rem;
                                    color: #666;
                                }
                            }
                        }
                        .react-datepicker__month{
                            margin: 0;
                            .react-datepicker__week{
                                display: flex;
                                .react-datepicker__day{
                                    flex: 1;
                                    font-family: $fontKodchasan;
                                    font-weight: bold;
                                    font-size: 1rem;
                                    padding: 6px 0;
                                    &:hover{
                                        background-color: rgba(0,0,0,0.05);
                                        color: #323232;
                                    }
                                    &--disabled{
                                        &:hover{
                                            background-color: unset;
                                            color: #ccc;
                                            cursor: default;
                                        }
                                    }
                                    &--selected{
                                        background-color: rgba(0,0,0,0.1);
                                        color: #323232;
                                        &:hover{
                                            background-color: rgba(0,0,0,0.1);
                                            color: #323232;
                                        }
                                    }
                                    &--keyboard-selected{
                                        background-color: transparent;
                                        color: #323232;
                                        &:hover{
                                            background-color: rgba(0,0,0,0.1);
                                            color: #323232;
                                        }
                                    }
                                    &--outside-month{
                                        pointer-events: none;
                                        color: #aaa;
                                        visibility: hidden;
                                    }
                                    &:focus{
                                        border: 0;
                                        outline: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .light-box-footer{
                padding: 10px 0;
                width: 100%;
                background-color: #FFEA00;
                text-align: center;
                font-weight: bold;
                font-size: 0.85rem;
                color: #666;
                cursor: pointer;
            }
        }
    }
}